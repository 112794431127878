import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-brand-model',
  templateUrl: './brand-model.component.html',
  styleUrls: ['./brand-model.component.css']
})
export class BrandModelComponent implements OnInit {

  details:any=[];

  constructor(public dialogRef: MatDialogRef<BrandModelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
   
   this.details=data.data;
   }

  ngOnInit() {
    console.log(this.details);
  }

}
