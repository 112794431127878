import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  brands: any = [
    {
      catogary: 'retail',
      name:'airlines catering',
      images:['assets/images/company/Catering/1.jpg','assets/images/company/Airlines/2.jpg','assets/images/company/Airlines/3.jpg','assets/images/company/Airlines/4.jpg','assets/images/company/Airlines/5.jpg'],
      src: 'assets/images/career-two.png',
      discription: 'Ever since its establishment, Airlines Catering has been one of the most sought-after caterers that has made countless wedding functions, private gatherings as well as corporate events memorable with the delicious food served at the respective venues. Our units are spread across Calicut, Malappuram and Kochin – always ready to serve you the best. The wide array of items on our menu has always pleased many a satisfied patron and their repetitive and continuous orders is the best form of feedback that we have been lauded with. Each cuisine, be it the Malabar cuisine, Arabic cuisine, Chinese or continental, all are carefully and hygienically prepared by responsible professionals in the respective fields, sticking to authentic recipes. Quality and taste is given utmost importance and both are never compromised upon. Our live bbq station is a crowd pleaser at all events.   .',
      title: 'Airlines Catering',
      id: 1,
      link: 'www.airlinescelestial.com'
    },
    {
      catogary: 'retail',
      name:'airlines celestial  restaurant',
      images:['assets/images/company/Airlines/1.jpg','assets/images/company/Airlines/2.jpg','assets/images/company/Airlines/3.jpg','assets/images/company/Airlines/4.jpg','assets/images/company/Airlines/5.jpg'],
      src: 'assets/images/career-one.png',
      discription: 'Having carved a remarkable name for itself in the world of restaurants serving the most delicious meals since its opening, Airlines Celestial Restaurant has been one of the most memorable venues for families to gather and bond for over 23 years. Situated on Kozhikode Road, Malappuram, Kerala, this restaurant was founded in 1996. Catering to over 1000 customers a day, our committed and cheerful staff are always ready to serve you some of the most nostalgic recipes that people keep coming back for. ‘Healthy eating’ is never compromised upon by our skilled chefs and our patrons are served with love, patience and passion. We are equipped with an in-house prayer hall as well as a kid’s play area to serve our guests better.  ',
      title: 'Airlines Celestial  Restaurant',
      id: 2,
      link: 'www.airlinescelestial.com'
    },
    {
      catogary: 'retail',
      name:'airlines central store',
      images:['assets/images/company/Central_Stores/1.jpg','assets/images/company/Airlines/2.jpg','assets/images/company/Airlines/3.jpg','assets/images/company/Airlines/4.jpg','assets/images/company/Airlines/5.jpg'],
      src: 'assets/images/career-three.png',
      discription: 'Food servicing is a huge responsibility requiring multiple areas that needs to be covered and we at Airlines Central Store are the right choice to simplify your tasks. Ranging from raw material supply, to delivering the right cuts of meat and veggies, to ensuring cleanliness and quality of the required raw materials, to timely delivery of the washed and packaged raw materials; and all other related preliminary services before the final product is delivered to the client - we got your back. We highly prioritize on maintaining consistency at all times. Rest assured, for each of our department catering to the same is enriched with the right number of professionals ensuring quality, cleanliness and punctuality. Our team ensures hassle-free handling of all services so that no assigned duty intertwines with another, which guarantees a smooth functioning throughout. ',
      title: 'Airlines Central Store',
      id: 3,
      link: 'www.airlinescelestial.com'
    },
    {
      catogary: 'retail',
      name:'the grillax',
      images:['assets/images/company/Grillax/1.jpg,assets/images/company/Grillax/3.jpg','assets/images/company/Grillax/4.jpg','assets/images/company/Grillax/5.jpg'],
      src: 'assets/images/grillax.png',
      discription: 'The current generation of fitness enthusiasts yet ardent food lovers have shown a conscious shift to suffice food cravings by avoiding junk to eating healthy; and Grillax caters to exactly this category of food lovers. Within a short span multiple branches have fallen under their belt with more expansions under way. Inclusive of a limited yet highly appetizing and appealing menu catering to the hunger pangs of both vegetarians and non-vegetarians, the healthy, grilled items served at Grillax is one to look out for. Quick and timely delivery of delicious food is prioritized upon, ensuring every customer leaves its premises happy and content. Grillax is known for the consistent distinctive flavors and aromatic kebabs, freshly prepared roti served hot upon request, along with accompaniments and the likes. This is what has given Grillax its magnetic appeal ever since its commencement. ',
      title: 'The Grillax',
      id: 4,
      link: 'www.thegrillax.com'
    },
    {
      catogary: 'retail',
      name:'final touch',
      images:['assets/images/company/Grillax/Final_Touch/1.jpg','assets/images/company/Grillax/Final_Touch/2.jpg','assets/images/company/Grillax/Final_Touch/3.jpg','assets/images/company/Grillax/Final_Touch/3.jpg'],
      src: 'assets/images/career-five.jpg',
      discription: 'Eat dessert first’’, said no one ever and this is where we at AGT render the sweetest service to our customers in the form of a plethora of sweet dishes to choose from and quite literally so! Just as the name suggests, Final Touch provides the inevitable finesse to one’s buffet arrangement by providing desserts to catering companies. Our clientele is spread far and wide between Trivandrum to Kasargod and it is with much pride, honor and commitment that we undertake each order that comes our way. Pastries, cakes, puddings, Arabic desserts and the likes are all prepared with love and patience by professionals in each field who uncompromisingly adhere to authentic recipes. Our unique and exclusive live dessert-counters are one to look out for. Future proposed expansions under this venture include sweet shops, bakeries, concept confectionaries and others.',
      title: 'Final Touch',
      id: 5,
      link: 'www.finaltouch.com'
    },
    {
      catogary: 'retail',
      name:'pumpkin restaurant',
      src: 'assets/images/pumpkin.png',
      discription: 'This venture under AGT is inclusive of a multi-cuisine fine-dining restaurant, a banquet hall with a crowd capacity of over 2500 pax, clean and sanitized hotel rooms as well as it contains a board room to hold private as well as corporate meetings. The ambience within the hotel appeals one and all. Pumpkin houses its guests ensuring to highly prioritize upon comfort and convenience. The courteous and professionally trained staff are always ready to render their best service. Ample parking space adds to the convenience of guests and visitors alike coupled with the prevalence of all related required facilities. Those who need to spend a few minutes in prayer can do so at the in-house prayer hall situated for the benefit of travelers and guests.     ',
      title: 'Pumpkin Restaurant',
      id: 6,
      link: 'www.pumpkinrestaurant.com'
    },
    {
      catogary: 'retail',
      name:'ejt',
      images:['assets/images/company/Ejt/1.jpg','assets/images/company/Ejt/2.jpg','assets/images/company/Ejt/3.jpg'],
      src: 'assets/images/career-six.jpg',
      discription: 'Just as ‘love’ is the secret ingredient which gives that magic touch of perfection to food prepared in a mother’s kitchen, so does a perfect dish call for secret, special ingredients. And this exactly is what AGTBA undertakes to deliver through EJT. Be it the signature biryani masala, fish masala, sambar mix, arabic grill seasoning, peri-peri seasoning, etc. packed masala powders for each, under the right proportions, inclusive of all necessary seasonings are sealed and delivered across restaurant units to ensure quality, consistency and taste whenever they are prepared and served. These packets have a shelf life of over 6 months for ease of storage.  Being customer-centric in nature, we give high regards to customer satisfaction and hence strive to live up to their expectations at all times.',
      title: 'EJT',
      id: 8,
      link: 'www.ejt.com'
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
