import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router,NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'opacity(1)', opacity: 0}),
          animate('200ms', style({transform: 'opacity(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'opacity(0)', opacity: 1}),
          animate('200ms', style({transform: 'opacity(1)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class TopnavbarComponent implements OnInit {
  showNav:boolean = false;
  currentUrl:any;

  constructor(private router: Router) {
    router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((e: NavigationEnd) => {
      this.currentUrl = e.url;  
      console.log(this.currentUrl);     
});
   }

  ngOnInit() {
   
  }
   
}
