import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-model',
  templateUrl: './success-model.component.html',
  styleUrls: ['./success-model.component.css']
})
export class SuccessModelComponent implements OnInit {


  message:any;

  constructor(public dialogRef: MatDialogRef<SuccessModelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.message=data.message;
    
  }

  ngOnInit(): void {
    console.log(this.message)
  }

}
