import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CarriersService } from 'src/app/Providers/carriers.service';
import { Validator } from 'src/app/_helpers/validators';
import { MapModelComponent } from '../map-model/map-model.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm:FormGroup;
  validator=new Validator;
  pattern1 =  "^[0-9_-]{10,12}";
  submitted:boolean=false;

  constructor(public formBuilder:FormBuilder,public carrier:CarriersService,public dailog:MatDialog) { }

  ngOnInit() {
  this.contactForm=this.formBuilder.group({
    name:['',Validators.required],
    email:['',[Validators.required, Validators.pattern(this.validator.emailPattern())]],
    mobile: ['', [Validators.required, Validators.pattern(this.pattern1), Validators.minLength(10)]],
    subject:['',Validators.required],
    message:['']
  })
  }

  get avf() { return this.contactForm.controls; }

  submitData(){
    this.submitted=true;
    if(this.contactForm.valid){
      var data={
        name:this.avf.name.value,
        email:this.avf.email.value,
        mobile:this.avf.mobile.value,
        subject:this.avf.subject.value,
        message:this.avf.message.value
      }
      this.carrier.sendContact(data).subscribe(resp=>{
        if(resp.status=='success'){
          console.log(resp);
          this.submitted=false;
          this.contactForm.reset();
        }
      })
    }
  }

  openMap() {
    const dialogRef = this.dailog.open(MapModelComponent, {
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
