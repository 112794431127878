import { EventPublishService } from './event-publish.service';
import { Injectable } from '@angular/core';
import { CommonProvider } from './common';
import { Router } from '@angular/router';
import { Api } from './api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public profile: any = {}
  public apiKey: string = ''
  public hasLoggedIn: boolean = false;
  public vendorId: any;
  public coinValue: any = 1;
  addressUpdate:boolean=false
  PROFILE = 'userProfile';
  APIKEY = 'apiKey';
  HAS_LOGGED_IN = 'hasLoggedIn';
  isFirstTime:boolean = false
  public forgot_password_email_mobile: any = '';
  // OTP RESEND TIMER

  // login
  public login_mobile: any = '';
  login_otp_resend_timer: any = null;
  constructor( public common: CommonProvider, public router: Router,
    public events:EventPublishService,
    ) { }

  updateLoginData(res) {
    this.profile = res.data;
    this.apiKey = res.api_token;
    localStorage.setItem(this.PROFILE,JSON.stringify(this.profile))
    localStorage.setItem(this.APIKEY,this.apiKey)
    localStorage.setItem(this.HAS_LOGGED_IN, JSON.stringify(true))
    this.hasLoggedIn = true;
  }
  logout() {
    localStorage.removeItem(this.PROFILE)
    localStorage.removeItem(this.APIKEY)
    localStorage.removeItem(this.HAS_LOGGED_IN)
    localStorage.clear()
    this.hasLoggedIn = false;
  }
  loadUser() {
    if(localStorage.getItem(this.APIKEY)){
      this.apiKey = localStorage.getItem(this.APIKEY);
      this.hasLoggedIn = true;
    }else{
      this.logout()
    }
    if(JSON.parse(localStorage.getItem(this.PROFILE))){
      this.profile = JSON.parse(localStorage.getItem(this.PROFILE));
    }else{
    }
  }

  checkForLandingPage() {
    if (localStorage.getItem(this.common.IS_FIRST_TIME)) this.isFirstTime = true;
  }

}
