import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { TopnavbarComponent } from '../topnavbar/topnavbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    BasicLayoutComponent,
    FooterComponent,
    TopnavbarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule ,
    FormsModule,
    ReactiveFormsModule
    // BsDropdownModule.forRoot(),
  ],
  exports: [
    BasicLayoutComponent,
    FooterComponent,
    TopnavbarComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
],
})
export class LayoutModule { }
