import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLayoutComponent } from './components/common/layout/basic-layout/basic-layout.component';
import { HomeComponent } from './views/home/home.component';
import { ContactComponent } from './views/contact/contact.component';
import { VenturesComponent } from './views/ventures/ventures.component';
import { AboutComponent } from './views/about/about.component';
import { CareersComponent } from './views/career/careers/careers.component';
import { CareerDetailComponent } from './views/career/career-detail/career-detail.component';
import { CareerFormComponent } from './views/career/career-form/career-form.component';
import { AirlinesComponent } from './business/airlines/airlines.component';


const routes: Routes = [
  { path: '',redirectTo: 'home', pathMatch: 'full', },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'contact', component: ContactComponent, pathMatch: 'full' },
      { path: 'ventures', component: VenturesComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent, pathMatch: 'full' },
      { path: 'careers', component: CareersComponent, pathMatch: 'full' },
      { path: 'career/:id', component: CareerDetailComponent, pathMatch: 'full' },
      { path: 'career-form/:id/:data', component: CareerFormComponent, pathMatch: 'full' },
      { path: 'business/:id', component:AirlinesComponent, pathMatch: 'full' },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled', enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
