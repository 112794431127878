import { Component, OnInit } from '@angular/core';
import { IMasonryGalleryImage } from 'ngx-masonry-gallery';
import { MatDialog } from '@angular/material/dialog';
import { BrandModelComponent } from '../brand-model/brand-model.component';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';


declare var $;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  tabbox = true;
  flag: any = "retail";
  brands: any = [
    {
      catogary: 'retail',
      src: 'assets/images/career-two.png',
      discription: 'Mend is the platform for people upgrading their wardrobe in accordance with current fashion trends. Experience the waves of change in the fashion world hitting the shelves from time to time. Our collections extends for Men,women and Junior. ',
      title: 'Airlines Celestial',
      id: 1,
      link: 'http://www.airlinescelestial.com/'
    },
    {
      catogary: 'retail',
      src: 'assets/images/career-one.png',
      discription: 'An exquisite retail fashion brands addressing all lifestyle needs. MLounge houses  apparels for men, women and kids, beauty products , accessories under one roof. Amenities like Gift stores, Coffee shop, Sports shop  ,Salon ,etc. are incorporated for a memorable shopping experience.',
      title: 'Airlines Catering',
      id: 2,
      link: 'http://www.airlinescelestial.com/'
    },
    {
      catogary: 'retail',
      src: 'assets/images/career-three.png',
      discription: 'An inspiring and highly brand specific collection of footwear exhibiting excellent trends. The collection shelves in TRY ANKLE is occupied with a spectacular array of footwear which hold a decent blend of formal, informal and trendy designs. These exceptionally eye-catching and amazing displays of footwear of all kind keeps the section robustly engaged',
      title: 'Airlines Central Store',
      id: 3,
      link: 'http://www.airlinescelestial.com/'
    },
    {
      catogary: 'retail',
      src: 'assets/images/grillax.png',
      discription: 'Barbados presents dazzling mix of western and Indian elements of uncompromised fashion. Ethnic clothing keeps the country visually colourful and rich in its own varied cultural heritages. The meticulous craftsmanship bridged with the classic designs puts you a way ahead in your special days.',
      title: 'The Grillax',
      id: 4,
      link: 'https://thegrillax.com/'
    },
    {
      catogary: 'retail',
      src: 'assets/images/career-five.jpg',
      discription: 'Exclusive showroom for all the baby products. Our product assortment is comprised of baby and children s clothes, shoes, toys, strollers, car seats, accessories and much more for ages 0-10. Get inspired by an excellent quality of materials and designs.',
      title: 'Final Touch',
      id: 5,
      link: 'www.finaltouch.com'
    },
    {
      catogary: 'retail',
      src: 'assets/images/pumpkin.png',
      discription: 'A complete area focused for the big day of every man. This is the one-stop shop for all bridegrooms out there that wishes their wedding shall be made rememberable. Wed&Wogue cover the groom’s long-list of demands indubitably with artistic designs from the nooks and corners of the world. ',
      title: 'Pumpkin',
      id: 6,
      link: 'www.pumpkinrestaurant.com'
    },
    {
      catogary: 'retail',
      src: 'assets/images/career-six.jpg',
      discription: 'A complete area focused for the big day of every man. This is the one-stop shop for all bridegrooms out there that wishes their wedding shall be made rememberable. Wed&Wogue cover the groom’s long-list of demands indubitably with artistic designs from the nooks and corners of the world. ',
      title: 'EJT',
      id: 8,
      link: 'www.ejt.com'
    },
  ];
  private urls: string[] = [
    'assets/images/gallery/1.jpg',
    'assets/images/gallery/2.jpg',
    'assets/images/gallery/3.jpg',
    'assets/images/gallery/4.jpg',
    'assets/images/gallery/5.jpg',
    'assets/images/gallery/6.jpg',
    'assets/images/gallery/7.jpg',
    'assets/images/gallery/8.jpg',
    'assets/images/gallery/9.jpg',
    'assets/images/gallery/10.jpg',
    'assets/images/gallery/11.jpg',
    'assets/images/gallery/12.jpg',
    'assets/images/gallery/13.jpg',
    
    'assets/images/gallery/15.jpg',
    'assets/images/gallery/16.jpg',
    'assets/images/gallery/17.jpg',
    'assets/images/gallery/18.jpg',
    'assets/images/gallery/19.jpg',
  
    'assets/images/gallery/21.jpg',
    'assets/images/gallery/22.jpg',
    'assets/images/gallery/23.jpg',
    'assets/images/gallery/24.jpg',
    'assets/images/gallery/14.jpg',
    'assets/images/gallery/20.jpg',
  ]
  public get images(): IMasonryGalleryImage[] {
    return this.urls.map(m => <IMasonryGalleryImage>{
      imageUrl: m
    });
  }
  constructor(public dailog: MatDialog,public router:Router) {
    
  }

  ngOnInit() {
    // var grid = document.querySelector('.grid');

    // var msnry = new Masonry( grid, {
    //   itemSelector: '.grid-item',
    //   columnWidth: '.grid-sizer',
    //   percentPosition: true
    // });

    // imagesLoaded( grid ).on( 'progress', function() {
    //   // layout Masonry after each image loads
    //   msnry.layout();
    // });

    // var s = $(".counts-holder");
    // var pos = s.position();
    // var stickermax = $(document).outerHeight();
    // var flag = true;
    // $(window).scroll(function () {
    //   var a = $(window).scrollTop();
    //   if (a >= pos.top - 300 && flag) {
    //     flag = false;
    //     console.log("in");
    //     $(".count").each(function () {
    //       $(this).prop("Counter", 0).animate({ Counter: $(this).data("count") }, {
    //         duration: 4000, easing: "swing", step: function (b) {
    //           $(this).text(Math.ceil(b))
    //         }
    //       })
    //     })
    //   }
    // });

  }

  customOptions: OwlOptions = {
    items: 1,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: true,
    autoplay:true,
    autoplayTimeout:4000,
  }




  tab(value) {
    this.flag = value;
  }

  openModel(prod) {
    this.router.navigate(['business',prod])
  }

  changeValue(event){
    console.log(event.target.value);
    this.flag=event.target.value;
  }
}

