import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-apply',
  templateUrl: './career-apply.component.html',
  styleUrls: ['./career-apply.component.css']
})
export class CareerApplyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
