// import {LoginPage} from './../pages/login/login';
import { Injectable, ComponentFactoryResolver } from '@angular/core';


import { environment as env } from '../../environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class CommonProvider {
  baseUrl = 'http://beta.api.anjos.shopping/orders';

  CART = 'cart';
  CART_UPDATED = 'cart:updated';
  IS_FIRST_TIME = 'migosisfirsttime';

 



}
