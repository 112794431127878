import { Injectable } from '@angular/core';
import { Api } from './api';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CarriersService {

  constructor(public api:Api) { }

  getJobdetail(id){
    let endpoint='jobs/'+id
    return this 
    .api
    .get(endpoint)
    .pipe(map(resp=>resp.json()))
  }
  

  addCareer(data,id) {
    let endpoint = 'job/apply/' + id;
        let fData = new FormData();
        for (var key in data) {
          console.log(key,data[key])
            fData.append(key, data[key]);
        }
            return this
                .api
                .postAsFormData(endpoint, fData)
                .pipe(map(resp => resp.json()));
        
  }

  
  sendContact(data){
    let endpoint='contact/send'
    return this
    .api
    .post(endpoint,data)
    .pipe(map(resp=>resp.json()))
  }
}
