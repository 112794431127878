import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './components/common/layout/layout.module';
import { HomeComponent } from './views/home/home.component';
import { ContactComponent } from './views/contact/contact.component';
import { VenturesComponent } from './views/ventures/ventures.component';
import { AboutComponent } from './views/about/about.component';
import {MatDialogModule} from '@angular/material/dialog';
import { BrandModelComponent } from './views/brand-model/brand-model.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CareersComponent } from './views/career/careers/careers.component';
import { CareerDetailComponent } from './views/career/career-detail/career-detail.component';
import { CareerApplyComponent } from './views/career/career-apply/career-apply.component';
import { CareerFormComponent } from './views/career/career-form/career-form.component';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  MatSelectModule } from '@angular/material/select';
import {MatFormFieldModule,} from '@angular/material/form-field'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SuccessModelComponent } from './views/success-model/success-model.component';
import { MapModelComponent } from './views/map-model/map-model.component';
import { AirlinesComponent } from './business/airlines/airlines.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarouselModule } from 'ngx-owl-carousel-o';





// import { MasonryModule } from 'angular2-masonry';
 
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    VenturesComponent,
    AboutComponent,
    BrandModelComponent,
    CareersComponent,
    CareerDetailComponent,
    CareerApplyComponent,
    CareerFormComponent,
    SuccessModelComponent,
    MapModelComponent,
    AirlinesComponent
  ],
  entryComponents:[
  BrandModelComponent,
  SuccessModelComponent,
  MapModelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    MasonryGalleryModule,
    // CarouselModule.forRoot(),
    MatDialogModule,
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    CarouselModule 
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
