import { Injectable } from '@angular/core';
import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { UserService } from './user';
import { environment as env } from '../../environments/environment.prod';
/**
 * Api is a generic REST Api handler. Set your API url first.
 */ 
@Injectable({ providedIn: 'root' })
export class Api {
  url: string = env.common_settings.url;

  constructor(public http: Http, public user: UserService) { }

  get(endpoint: string, params?: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "get" });
    }

    // Support easy query params for GET requests
    if (params) {
      let p = new URLSearchParams();
      for (let k in params) {
        p.set(k, params[k]);
      }
      // Set the search field if we have params and don't already have a search field
      // set in options.
      options.search = !options.search && p || options.search;
    }

    return this
      .http
      .get(this.url + '/' + endpoint, options);
  }

  post(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.url + '/' + endpoint, body, options);
  }
  postAsJson(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.url + '/' + endpoint, body, options);
  }
  postAsFormData(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "post" });
    }
    return this
      .http
      .post(this.url + '/' + endpoint, body, options);
  }
  put(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "put" });
    }
    return this
      .http
      .put(this.url + '/' + endpoint, body, options);
  }

  delete(endpoint: string, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "delete" });
    }
    return this
      .http
      .delete(this.url + '/' + endpoint, options);
  }
  patch(endpoint: string, body: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Authorization': 'Bearer ' + this.user.apiKey
      });
      options = new RequestOptions({ headers: headers, method: "patch" });
    }
    return this
      .http
      .patch(this.url + '/' + endpoint, body, options);
  }






  getTempProducts(endpoint: string, params?: any, options?: RequestOptions) {
    if (!options) {
      let headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer T3V5dWJjaGtnd3pNakczRHhueTJQa1FNZ01BYTd4WE4='
      });
      options = new RequestOptions({ headers: headers, method: "get" });
    }

    // Support easy query params for GET requests
    if (params) {
      let p = new URLSearchParams();
      for (let k in params) {
        p.set(k, params[k]);
      }
      // Set the search field if we have params and don't already have a search field
      // set in options.
      options.search = !options.search && p || options.search;
    }

    return this
      .http
      .get('http://anjos.beta.eximuz.com/api/v1/admin/catalog', options);
  }
}
