import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarriersService } from 'src/app/Providers/carriers.service';
import { Validator } from 'src/app/_helpers/validators';
declare var $;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  footerForm:FormGroup
  submitted:boolean=false;
  validator=Validator


  constructor(public formBuilder:FormBuilder,public carrier:CarriersService) { 
  }

  ngOnInit() {
    this.footerForm=this.formBuilder.group({
    name:['',Validators.required],
    email:['',Validators.required],
    message:['',],
    mobile:['',[Validators.required, Validators.pattern("[0-9]{0-10}")]],
    subject:['',]
    })
  }

  closemodel(){
    $(".model").css("display", "none");
  }

  get acf() { return this.footerForm.controls; }

  sendMessage(){
    this.submitted=true;
    if(this.footerForm.valid){
      var data={
        name:this.acf.name.value,
        email:this.acf.email.value,
        message:this.acf.message.value,
      }
     this.carrier.sendContact(data).subscribe(resp=>{
       if(resp.status=='success'){
        console.log(resp)
        this.submitted=false;
       }

     })
    }
  }
  
}
