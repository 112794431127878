import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarriersService } from 'src/app/Providers/carriers.service'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-career-detail',
  templateUrl: './career-detail.component.html',
  styleUrls: ['./career-detail.component.css']
})
export class CareerDetailComponent implements OnInit {

  data:any;
  id:number;
  job:any=[];
  src:any;
  loader:boolean=false;

  constructor(public route:ActivatedRoute,public carrirs:CarriersService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
   this.route.params.subscribe(val=>{
    this.data=val['id']
    console.log(this.data)
   })
   if(this.data =='airlines celestial  restaurant'){
     console.log("llllllllll")
     this.id=0
     this.src='assets/images/career-one.png'
   }else if(this.data=='airlines catering'){
     this.id=1
     this.src="assets/images/career-two.png"
   }else if(this.data=='airlines central store'){
     this.id=2
     this.src="assets/images/career-three.png"
   }else if(this.data=='the grillax'){
     this.id=3
     this.src="assets/images/grillax.png"
   }else if(this.data=='final touch'){
    this.id=4
    this.src='assets/images/career-five.jpg'
  }else if(this.data=='pumpkin restaurant'){
    this.id=5
    this.src='assets/images/pumpkin.png'
  }else if(this.data=='kloudkart'){
    this.id=6
    this.src='assets/images/career-four.png'
  }else if(this.data=='ejt'){
    this.id=7
    this.src='assets/images/career-six.jpg'
  }
   console.log(this.id);

   this.getJobDetail()
  }


  getJobDetail(){
    this.spinner.show();
   this.carrirs.getJobdetail(this.id).subscribe(resp=>{
     if(resp.status=="success"){
       this.spinner.hide();
       console.log(resp)
       this.job=resp.data;
       console.log(this.job)
     }
   })
  }



}
